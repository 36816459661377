export const DonateIcon = () => (
  <svg
    width="100px"
    height="100px"
    viewBox="0 0 1024 1024"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    transform="rotate(270)matrix(-1, 0, 0, 1, 0, 0)"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M249.6 460.8l108.8 211.2 202.666667-83.2 93.866666-270.933333-315.733333 17.066666z"
        fill="#FCA664"
      ></path>
      <path
        d="M320 768m-166.4 0a166.4 166.4 0 1 0 332.8 0 166.4 166.4 0 1 0-332.8 0Z"
        fill="#CC9933"
      ></path>
      <path
        d="M320 576c-106.666667 0-192 85.333333-192 192s85.333333 192 192 192 192-85.333333 192-192-85.333333-192-192-192z m0 341.333333c-83.2 0-149.333333-66.133333-149.333333-149.333333s66.133333-149.333333 149.333333-149.333333 149.333333 66.133333 149.333333 149.333333-66.133333 149.333333-149.333333 149.333333z"
        fill="#EFC75E"
      ></path>
      <path
        d="M298.666667 704h42.666666v170.666667h-42.666666z"
        fill="#EFC75E"
      ></path>
      <path
        d="M275.2 768c21.333333 40.533333 68.266667 57.6 108.8 36.266667l352-181.333334c21.333333-10.666667 36.266667-25.6 46.933333-40.533333 36.266667-68.266667 119.466667-228.266667 174.933334-366.933333l-388.266667 185.6-102.4 153.6-145.066667 76.8c-55.466667 27.733333-72.533333 89.6-46.933333 136.533333z"
        fill="#FECB98"
      ></path>
      <path
        d="M644.266667 64L292.266667 198.4c-14.933333 4.266667-32 21.333333-46.933334 36.266667l-119.466666 160c-21.333333 32-25.6 72.533333-10.666667 108.8 8.533333 21.333333 36.266667 72.533333 66.133333 130.133333C215.466667 597.333333 264.533333 576 320 576c8.533333 0 19.2 0 27.733333 2.133333l-44.8-89.6 98.133334-87.466666h170.666666s330.666667-46.933333 388.266667-185.6L644.266667 64z"
        fill="#FECB98"
      ></path>
      <path
        d="M388.266667 768c-27.733333 12.8-59.733333 0-70.4-27.733333-12.8-27.733333 0-59.733333 27.733333-70.4 25.6-12.8 68.266667 85.333333 42.666667 98.133333z"
        fill="#FFCDD2"
      ></path>
    </g>
  </svg>
);

export const createAccountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      d="M71.0717 80.5862C70.2905 82.8518 68.1616 84.5705 65.5249 84.5705H34.4702C30.5053 84.5705 27.6733 80.7033 28.8842 76.9143L32.8296 64.5705C33.6108 62.1096 36.4038 60.9768 38.7085 62.1487C41.2866 63.4572 45.0366 64.7268 49.978 64.7268C54.9194 64.7268 58.6889 63.4572 61.2475 62.1487C62.1655 61.6799 63.1421 61.5822 64.06 61.7776C61.7749 63.3596 60.271 66.0158 60.271 69.0041C60.271 73.8479 64.2163 77.7932 69.06 77.7932H71.0132V79.7463C71.0327 80.0393 71.0522 80.3127 71.0717 80.5862Z"
      fill="#FCA664"
    ></path>
    <path
      d="M49.9961 60.4492C56.3495 60.4492 61.5 55.2987 61.5 48.9453C61.5 42.5919 56.3495 37.4414 49.9961 37.4414C43.6427 37.4414 38.4922 42.5919 38.4922 48.9453C38.4922 55.2987 43.6427 60.4492 49.9961 60.4492Z"
      fill="#FCA664"
    ></path>
    <path
      d="M91.9727 58.2031H88.6133C88.5742 53.3985 84.6484 49.4922 79.8242 49.4922C75 49.4922 71.0742 53.3985 71.0352 58.2031H62.3047C64.2383 55.625 65.3906 52.4219 65.3906 48.9649C65.3906 45.8203 64.4531 42.9102 62.832 40.4688V40.4492C63.6133 38.0274 66.3477 36.8946 68.6328 38.0078C70.957 39.1406 74.2578 40.1953 78.5547 40.1953C82.8516 40.1953 86.1523 39.1602 88.4766 38.0078C90.7617 36.8946 93.5156 38.0274 94.2773 40.4688L97.5195 50.586C98.7695 54.336 95.9375 58.2031 91.9727 58.2031Z"
      fill="#FCA664"
    ></path>
    <path
      d="M78.5742 36.2695C84.3452 36.2695 89.0234 31.5913 89.0234 25.8203C89.0234 20.0494 84.3452 15.3711 78.5742 15.3711C72.8033 15.3711 68.125 20.0494 68.125 25.8203C68.125 31.5913 72.8033 36.2695 78.5742 36.2695Z"
      fill="#FCA664"
    ></path>
    <path
      d="M37.6928 58.2031H8.02487C4.06003 58.2031 1.228 54.336 2.43893 50.5664L5.68112 40.4492C6.46237 38.0274 9.19675 36.8946 11.4819 37.9883C13.8061 39.1211 17.1069 40.1758 21.4038 40.1758C25.7007 40.1758 29.0014 39.1211 31.3257 37.9883C33.6108 36.875 36.3647 38.0078 37.1264 40.4297V40.4492C35.5053 42.8906 34.5678 45.8008 34.5678 48.9453C34.5874 52.4219 35.7397 55.625 37.6928 58.2031Z"
      fill="#FCA664"
    ></path>
    <path
      d="M21.4258 36.2695C27.1967 36.2695 31.875 31.5913 31.875 25.8203C31.875 20.0494 27.1967 15.3711 21.4258 15.3711C15.6548 15.3711 10.9766 20.0494 10.9766 25.8203C10.9766 31.5913 15.6548 36.2695 21.4258 36.2695Z"
      fill="#FCA664"
    ></path>
    <path
      d="M90.5625 64.1211H84.7031V58.2617C84.7031 55.5664 82.5156 53.3789 79.8203 53.3789C77.125 53.3789 74.9375 55.5664 74.9375 58.2617V64.1211H69.0781C66.3828 64.1211 64.1953 66.3086 64.1953 69.0039C64.1953 71.6992 66.3828 73.8867 69.0781 73.8867H74.9375V79.7461C74.9375 82.4414 77.125 84.6289 79.8203 84.6289C82.5156 84.6289 84.7031 82.4414 84.7031 79.7461V73.8867H90.5625C93.2578 73.8867 95.4453 71.6992 95.4453 69.0039C95.4453 66.3086 93.2578 64.1211 90.5625 64.1211Z"
      fill="url(#paint0_linear_29474_99823)"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_29474_99823"
        x1="95.4453"
        y1="84.6289"
        x2="58.5819"
        y2="77.6926"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F54A00"></stop>
        <stop offset="1" stop-color="#FF9252"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export const CreateChallengeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <g clip-path="url(#clip0_29474_99833)">
      <path
        d="M63.8055 43.2305C68.083 46.8586 68.6264 47.3629 70.5088 48.984C71.8881 47.801 73.0715 46.7248 78.0877 42.5182C87.2943 34.7875 93.9463 29.2057 93.9463 20.9604C93.9463 13.1439 88.2043 7.02148 80.8742 7.02148C75.9504 7.02148 72.6832 9.79961 70.6318 12.6291C68.6893 9.79102 65.5307 7.02148 60.5926 7.02148C53.2627 7.02148 47.5234 13.1441 47.5234 20.9604C47.5232 29.4146 54.0008 34.9105 63.8055 43.2305Z"
        fill="url(#paint0_linear_29474_99833)"
      ></path>
      <path
        d="M16.758 91.3966C24.4338 86.1819 32.8324 84.1893 41.973 85.713L56.6215 88.2325C62.2467 89.1702 67.4025 88.877 71.8557 87.4122C81.0682 84.3419 90.2906 74.6721 92.7348 71.0059C96.1334 65.9083 98.0004 60.3419 99.1725 57.6468C100.286 55.0686 100.286 53.2522 99.1139 52.1975C97.766 50.9669 95.4811 51.6114 93.7816 52.5491C91.0865 54.0725 88.223 58.4671 85.2934 62.6856C81.485 68.1934 74.9615 71.4747 68.2816 71.4747H47.0705V65.6153H55.8596C71.4453 65.6153 78.418 62.6856 78.418 59.7559C78.418 57.2952 73.5549 56.1817 71.9141 55.8303C65.5275 54.3655 51.1133 53.6624 41.2697 49.2678C39.4945 48.4911 37.482 48.0552 35.3521 48.0372C32.0127 48.0089 28.3834 49.0493 24.9219 51.7286C24.8637 51.7286 24.8637 51.7286 24.8049 51.7872C22.5197 53.545 19.3947 56.1231 15.176 59.8145C11.6016 62.9784 8.14473 67.0214 2.10938 72.1778L0 73.9942L14.4143 92.9788L16.758 91.3966Z"
        fill="#FCA664"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_29474_99833"
        x1="93.9463"
        y1="48.984"
        x2="39.6006"
        y2="37.6712"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F54A00"></stop>
        <stop offset="1" stop-color="#FF9252"></stop>
      </linearGradient>
      <clipPath id="clip0_29474_99833">
        <rect width="100" height="100" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

export const ShareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      d="M50.0033 28.3333L42.9833 18.3333L40.85 16.1983C40.0532 15.4051 39.4209 14.4623 38.9895 13.424C38.558 12.3858 38.3359 11.2726 38.3359 10.1483C38.3359 9.02396 38.558 7.91073 38.9895 6.87249C39.4209 5.83426 40.0532 4.89147 40.85 4.09827C42.4845 2.53773 44.6576 1.66699 46.9175 1.66699C49.1774 1.66699 51.3504 2.53773 52.985 4.09827L55.0033 6.11494L57.02 4.09827C58.6579 2.53777 60.8335 1.66732 63.0958 1.66732C65.3581 1.66732 67.5337 2.53777 69.1716 4.09827C70.7728 5.70465 71.6718 7.88022 71.6718 10.1483C71.6718 12.4163 70.7728 14.5919 69.1716 16.1983L58.5033 26.8499L56.67 28.3333H50.0033Z"
      fill="url(#paint0_linear_29474_99837)"
    ></path>
    <path
      d="M51.3307 26.6668H57.2641C57.6818 26.6694 58.097 26.7311 58.4974 26.8502C59.4851 27.0862 60.3512 27.6777 60.9305 28.5117C61.5098 29.3458 61.7617 30.3639 61.638 31.3719C61.5144 32.3798 61.0238 33.3069 60.2601 33.9761C59.4963 34.6454 58.5128 35.0099 57.4974 35.0002C58.0816 34.9993 58.6595 35.1214 59.1935 35.3584C59.7275 35.5953 60.2057 35.942 60.597 36.3758C60.9884 36.8095 61.2841 37.3208 61.4651 37.8763C61.646 38.4318 61.7082 39.0191 61.6474 39.6002C61.5007 40.6512 60.9734 41.6119 60.1654 42.3001C59.3575 42.9882 58.3251 43.3559 57.2641 43.3335H55.8307C56.415 43.3327 56.9928 43.4547 57.5268 43.6917C58.0608 43.9287 58.539 44.2753 58.9304 44.7091C59.3217 45.1429 59.6175 45.6541 59.7984 46.2096C59.9794 46.7651 60.0415 47.3524 59.9807 47.9335C59.834 48.9846 59.3067 49.9453 58.4988 50.6334C57.6908 51.3216 56.6584 51.6893 55.5974 51.6668H54.1641C54.7483 51.666 55.3262 51.788 55.8602 52.025C56.3942 52.262 56.8724 52.6086 57.2637 53.0424C57.655 53.4762 57.9508 53.9874 58.1318 54.5429C58.3127 55.0984 58.3748 55.6858 58.3141 56.2668C58.1674 57.3179 57.64 58.2786 56.8321 58.9668C56.0241 59.6549 54.9918 60.0226 53.9307 60.0002H33.3141C30.7806 60.0284 28.2663 59.5584 25.9141 58.6168C21.8865 57.1542 17.6121 56.4918 13.3307 56.6668H4.9974C4.11415 56.6642 3.26784 56.3122 2.64329 55.6876C2.01874 55.0631 1.6667 54.2167 1.66406 53.3335V33.3335C1.6667 32.4503 2.01874 31.6039 2.64329 30.9794C3.26784 30.3548 4.11415 30.0028 4.9974 30.0002H9.0474C12.7529 30.0288 16.3756 28.9047 19.4141 26.7835C21.2968 25.432 23.0691 23.9328 24.7141 22.3002C27.1995 19.7868 30.5794 18.3605 34.1141 18.3335H42.9807L51.3307 26.6668Z"
      fill="#FCA664"
    ></path>
    <path
      d="M28.3359 73.7468C28.3355 74.2908 28.4547 74.8283 28.6852 75.3211C28.9156 75.8139 29.2515 76.25 29.6693 76.5985L54.0693 96.2052C55.3352 97.2103 56.8314 97.8846 58.423 98.1671C60.0145 98.4497 61.6514 98.3316 63.1859 97.8235L83.9159 90.4185C84.6349 90.1617 85.3926 90.0304 86.1559 90.0302H96.6693C97.1113 90.0302 97.5352 89.8546 97.8478 89.542C98.1604 89.2294 98.3359 88.8055 98.3359 88.3635V75.0135C98.3359 74.5715 98.1604 74.1475 97.8478 73.835C97.5352 73.5224 97.1113 73.3468 96.6693 73.3468H87.2243C85.7818 73.3468 84.3782 72.879 83.2243 72.0135L80.4693 69.9418C79.6038 69.2923 78.6152 68.8256 77.5636 68.5703C76.5121 68.315 75.4196 68.2764 74.3526 68.4568L47.7859 72.8118C46.9335 72.9334 46.1488 73.3445 45.5635 73.9759C44.9781 74.6074 44.6277 75.421 44.5709 76.2802C44.5581 76.7643 44.6419 77.2462 44.8174 77.6975C44.993 78.1489 45.2568 78.5608 45.5934 78.909C45.93 79.2572 46.3326 79.5349 46.7778 79.7256C47.2229 79.9164 47.7016 80.0166 48.1859 80.0202H65.0026L57.5193 86.3535C57.2765 86.5575 56.9726 86.6749 56.6557 86.6869C56.3388 86.699 56.0269 86.6051 55.7693 86.4202L34.3193 70.7702C33.7605 70.3711 33.1037 70.1316 32.4192 70.0772C31.7348 70.0228 31.0484 70.1557 30.4336 70.4615C29.8189 70.7673 29.2989 71.2346 28.9294 71.8134C28.5598 72.3921 28.3547 73.0604 28.3359 73.7468Z"
      fill="#FECB98"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_29474_99837"
        x1="71.6718"
        y1="28.3333"
        x2="33.0903"
        y2="19.2579"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F54A00"></stop>
        <stop offset="1" stop-color="#FF9252"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export const GoldMedal = () => (
  <svg
    width="50px"
    height="50px"
    viewBox="-3.5 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M9.73795 18.8436L12.9511 20.6987L6.42625 32L4.55349 27.8233L9.73795 18.8436Z"
        fill="#CE4444"
      ></path>{" "}
      <path
        d="M9.73795 18.8436L6.52483 16.9885L0 28.2898L4.55349 27.8233L9.73795 18.8436Z"
        fill="#983535"
      ></path>{" "}
      <path
        d="M14.322 18.8436L11.1088 20.6987L17.6337 32L19.5064 27.8233L14.322 18.8436Z"
        fill="#983535"
      ></path>{" "}
      <path
        d="M14.322 18.8436L17.5351 16.9885L24.0599 28.2898L19.5064 27.8233L14.322 18.8436Z"
        fill="#CE4444"
      ></path>{" "}
      <path
        d="M22.9936 11.0622C22.9936 17.1716 18.0409 22.1243 11.9314 22.1243C5.82194 22.1243 0.869249 17.1716 0.869249 11.0622C0.869249 4.9527 5.82194 0 11.9314 0C18.0409 0 22.9936 4.9527 22.9936 11.0622Z"
        fill="url(#paint0_linear_103_1801)"
      ></path>{" "}
      <path
        d="M20.5665 11.0621C20.5665 15.8311 16.7004 19.6972 11.9315 19.6972C7.16247 19.6972 3.29645 15.8311 3.29645 11.0621C3.29645 6.29315 7.16247 2.42713 11.9315 2.42713C16.7004 2.42713 20.5665 6.29315 20.5665 11.0621Z"
        fill="#A88300"
      ></path>{" "}
      <path
        d="M21.0477 11.984C21.0477 16.7641 17.1727 20.6391 12.3926 20.6391C7.61251 20.6391 3.73748 16.7641 3.73748 11.984C3.73748 7.20389 7.61251 3.32887 12.3926 3.32887C17.1727 3.32887 21.0477 7.20389 21.0477 11.984Z"
        fill="#C28B37"
      ></path>{" "}
      <path
        d="M20.5868 11.0621C20.5868 15.8422 16.7118 19.7172 11.9317 19.7172C7.15159 19.7172 3.27656 15.8422 3.27656 11.0621C3.27656 6.28205 7.15159 2.40702 11.9317 2.40702C16.7118 2.40702 20.5868 6.28205 20.5868 11.0621Z"
        fill="#C09525"
      ></path>{" "}
      <path
        d="M11.9781 5.04096L13.8451 8.77502L17.5792 9.24178L15.0151 12.117L15.7122 16.2431L11.9781 14.3761L8.24404 16.2431L8.94729 12.117L6.37701 9.24178L10.1111 8.77502L11.9781 5.04096Z"
        fill="url(#paint1_linear_103_1801)"
      ></path>{" "}
      <defs>
        {" "}
        <linearGradient
          id="paint0_linear_103_1801"
          x1="11.1804"
          y1="4.03192"
          x2="12.6813"
          y2="31.965"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stop-color="#FFC600"></stop>{" "}
          <stop offset="1" stop-color="#FFDE69"></stop>{" "}
        </linearGradient>{" "}
        <linearGradient
          id="paint1_linear_103_1801"
          x1="11.9783"
          y1="5.04096"
          x2="11.9783"
          y2="16.2431"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stop-color="#FFFCDD"></stop>{" "}
          <stop offset="1" stop-color="#FFE896"></stop>{" "}
        </linearGradient>{" "}
      </defs>{" "}
    </g>
  </svg>
);

export const SilverMedal = () => (
  <svg
    width="50px"
    height="50px"
    viewBox="-3.5 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M9.73779 18.8436L12.9509 20.6987L6.42609 32.0001L4.55333 27.8234L9.73779 18.8436Z"
        fill="#418ED6"
      ></path>{" "}
      <path
        d="M9.73779 18.8436L6.52467 16.9885L-0.000155079 28.2899L4.55333 27.8234L9.73779 18.8436Z"
        fill="#2B63A6"
      ></path>{" "}
      <path
        d="M14.3218 18.8436L11.1087 20.6987L17.6335 32.0001L19.5062 27.8234L14.3218 18.8436Z"
        fill="#2B63A6"
      ></path>{" "}
      <path
        d="M14.3218 18.8436L17.5349 16.9885L24.0597 28.2899L19.5062 27.8234L14.3218 18.8436Z"
        fill="#418ED6"
      ></path>{" "}
      <circle cx="12.0246" cy="11.0622" r="11.0622" fill="#E3E3E3"></circle>{" "}
      <circle cx="12.0247" cy="11.0621" r="8.63501" fill="#595959"></circle>{" "}
      <mask
        id="mask0_103_1231"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="19"
        height="18"
      >
        {" "}
        <circle
          cx="12.4857"
          cy="11.984"
          r="8.65511"
          fill="#C28B37"
        ></circle>{" "}
      </mask>{" "}
      <g mask="url(#mask0_103_1231)">
        {" "}
        <circle
          cx="12.0247"
          cy="11.0622"
          r="8.65511"
          fill="url(#paint0_linear_103_1231)"
        ></circle>{" "}
      </g>{" "}
      <path
        d="M12.0713 5.04102L13.9383 8.77508L17.6724 9.24183L15.1083 12.1171L15.8054 16.2432L12.0713 14.3762L8.33724 16.2432L9.04049 12.1171L6.47021 9.24183L10.2043 8.77508L12.0713 5.04102Z"
        fill="url(#paint1_linear_103_1231)"
      ></path>{" "}
      <defs>
        {" "}
        <linearGradient
          id="paint0_linear_103_1231"
          x1="12.0247"
          y1="2.4071"
          x2="12.0247"
          y2="19.7173"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stop-color="#9CA1A3"></stop>{" "}
          <stop offset="1" stop-color="#9CA1A3" stop-opacity="0"></stop>{" "}
        </linearGradient>{" "}
        <linearGradient
          id="paint1_linear_103_1231"
          x1="12.0713"
          y1="5.04102"
          x2="12.0713"
          y2="16.2432"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stop-color="#F1F5F5"></stop>{" "}
          <stop offset="0.0001" stop-color="white"></stop>{" "}
          <stop offset="1" stop-color="#F1F5F5"></stop>{" "}
        </linearGradient>{" "}
      </defs>{" "}
    </g>
  </svg>
);

export const BronzeMedal = () => (
  <svg
    width="50px"
    height="50px"
    viewBox="-3.5 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M9.73779 18.8436L12.9509 20.6987L6.42609 32.0001L4.55333 27.8234L9.73779 18.8436Z"
        fill="#AA75CB"
      ></path>{" "}
      <path
        d="M9.73779 18.8436L6.52467 16.9885L-0.000155079 28.2899L4.55333 27.8234L9.73779 18.8436Z"
        fill="#73488D"
      ></path>{" "}
      <path
        d="M14.3218 18.8436L11.1087 20.6987L17.6335 32.0001L19.5062 27.8234L14.3218 18.8436Z"
        fill="#73488D"
      ></path>{" "}
      <path
        d="M14.3218 18.8436L17.5349 16.9885L24.0597 28.2899L19.5062 27.8234L14.3218 18.8436Z"
        fill="#AA75CB"
      ></path>{" "}
      <circle cx="12.0246" cy="11.0622" r="11.0622" fill="#DC9E42"></circle>{" "}
      <circle cx="12.0247" cy="11.0621" r="8.63501" fill="#734C12"></circle>{" "}
      <mask
        id="mask0_103_1242"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="19"
        height="18"
      >
        {" "}
        <circle
          cx="12.4857"
          cy="11.984"
          r="8.65511"
          fill="#C28B37"
        ></circle>{" "}
      </mask>{" "}
      <g mask="url(#mask0_103_1242)">
        {" "}
        <circle
          cx="12.0247"
          cy="11.0622"
          r="8.65511"
          fill="#A36D1D"
        ></circle>{" "}
      </g>{" "}
      <path
        d="M12.0713 5.04102L13.9383 8.77508L17.6724 9.24183L15.1083 12.1171L15.8054 16.2432L12.0713 14.3762L8.33724 16.2432L9.04049 12.1171L6.47021 9.24183L10.2043 8.77508L12.0713 5.04102Z"
        fill="url(#paint0_linear_103_1242)"
      ></path>{" "}
      <defs>
        {" "}
        <linearGradient
          id="paint0_linear_103_1242"
          x1="12.0713"
          y1="5.04102"
          x2="12.0713"
          y2="16.2432"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stop-color="#FCFF80"></stop>{" "}
          <stop offset="0.401042" stop-color="#FDE870"></stop>{" "}
          <stop offset="1" stop-color="#FFC759"></stop>{" "}
        </linearGradient>{" "}
      </defs>{" "}
    </g>
  </svg>
);
